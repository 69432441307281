import { Injectable } from '@angular/core';
import { Observable } from '../../../../../node_modules/rxjs';
import { Model } from '../../../common/model/model';
import { ModelStatus } from '../../../common/enum/model-status';
import { ModelStatusInfo } from '../../../common/model/model-status-info';
import { HttpClient } from '../../../../../node_modules/@angular/common/http';
import { map } from '../../../../../node_modules/rxjs/operators';
import { ApiHttpResponse } from '../api/base/api-http-response';
import { ApiDeviceModelListRes } from '../api/api-device-model-list-res';
import { isArray, isNullOrUndefined } from 'util';
import { ImageInfo } from '../../../common/model/image-info';
import { ApiDeviceModelReq } from '../api/api-device-model-req';
import { ApiDeviceModelDetailRes } from '../api/api-device-model-detail-res';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  statusInfoList: Array<ModelStatusInfo>;

  constructor(private $httpClient: HttpClient) {
    const modelContinued = new ModelStatusInfo();
    modelContinued.name = '正常';
    modelContinued.status = ModelStatus.CONTINUED;

    const modelDiscontinued = new ModelStatusInfo();
    modelDiscontinued.name = '已停产';
    modelDiscontinued.status = ModelStatus.DISCONTINUED;

    this.statusInfoList = new Array<ModelStatusInfo>(
      modelContinued, modelDiscontinued
    );
  }

  fetchList(): Observable<Array<Model>> {
    return this.$httpClient.get<ApiHttpResponse<Array<ApiDeviceModelListRes>>>('/sys/device/model/list').pipe(
      map(body => {
        const list = new Array<Model>();
        if (isArray(body.data)) {
          body.data.forEach(item => {
            const model = new Model();
            model.id = item.id;
            model.name = item.name;
            model.imgUrl = item.img.url;
            model.imageInfo = new ImageInfo();
            model.imageInfo.id = item.img.id;
            model.imageInfo.url = item.img.url;
            model.statusInfo = this.statusInfoList.find(statusInfo => statusInfo.status === item.state);
            list.push(model);
          });
        }
        return list;
      })
    );
  }

  fetchOne(id: number): Observable<Model> {
    return this.$httpClient
      .get<ApiHttpResponse<ApiDeviceModelDetailRes>>(`/sys/device/model/${id}`)
      .pipe(
        map(body => {
          const model = new Model();
          model.id = body.data.id;
          model.name = body.data.name;
          model.imageInfo = new ImageInfo();
          model.imageInfo.id = body.data.img.id;
          model.imageInfo.url = body.data.img.url;
          model.remark = body.data.remark;
          return model;
        })
      );
  }

  toggleStatus(modelId: number): Observable<void> {
    return this.$httpClient.put<void>(`/sys/device/model/${modelId}/state`, null);
  }

  save(model: Model): Observable<void> {
    const body: ApiDeviceModelReq = {
      'img': {
        'id': model.imageInfo.id,
        'url': model.imageInfo.url
      },
      'name': model.name,
      'remark': model.remark
    };

    if (isNullOrUndefined(model.id)) {
      return this.$httpClient.post<void>('/sys/device/model', body);
    } else {
      return this.$httpClient.put<void>(`/sys/device/model/${model.id}`, body);
    }
  }
}
