import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PricingModel } from '../../../common/model/pricing-model';
import { HttpClient } from '../../../../../node_modules/@angular/common/http';
import { map } from '../../../../../node_modules/rxjs/operators';
import { ApiHttpResponse } from '../api/base/api-http-response';
import { ApiDeviceValuationListRes } from '../api/api-device-valuation-list-res';
import { isArray, isNullOrUndefined } from 'util';
import { ApiDeviceModelReq } from '../api/api-device-model-req';
import { ApiDeviceValuationReq } from '../api/api-device-valuation-req';
import { ApiDeviceValuationDetailRes } from '../api/api-device-valuation-detail-res';

@Injectable({
  providedIn: 'root'
})
export class PricingModelService {

  constructor(private $httpClient: HttpClient) { }

  fetchList(): Observable<Array<PricingModel>> {
    return this.$httpClient.get<ApiHttpResponse<Array<ApiDeviceValuationListRes>>>('/sys/device/valuation/list').pipe(
      map(res => {
        const list = new Array<PricingModel>();
        if (isArray(res.data)) {
          res.data.forEach(item => {
            const model = new PricingModel();
            model.id = item.id;
            model.name = item.name;
            model.oncePrice = item.price;
            model.onceDuration = item.duration;
            list.push(model);
          });
        }
        return list;
      })
    );
  }

  fetchOne(id: number): Observable<PricingModel> {
    return this.$httpClient.get<ApiHttpResponse<ApiDeviceValuationDetailRes>>(`/sys/device/valuation/${id}`).pipe(
      map(body => {
        const model = new PricingModel();
        model.id = body.data.id;
        model.name = body.data.name;
        model.onceDuration = body.data.duration;
        model.oncePrice = body.data.price;
        model.remark = body.data.remark;
        return model;
      })
    );
  }

  save(pricingModel: PricingModel): Observable<void> {
    const body: ApiDeviceValuationReq = {
      'duration': pricingModel.onceDuration,
      'name': pricingModel.name,
      'price': pricingModel.oncePrice,
      'remark': pricingModel.remark
    };

    if (isNullOrUndefined(pricingModel.id)) {
      return this.$httpClient.post<void>('/sys/device/valuation', body);
    } else {
      return this.$httpClient.put<void>(`/sys/device/valuation/${pricingModel.id}`, body);
    }
  }
}
