export enum WithdrawTypeEnum {
  /**
   * 待审核
   */
  APPLY = 'apply',

  /**
   * 待打款
   */
  AUDIT = 'audit',

  /**
   * 已打款
   */
  TRANSFER = 'transfer',

  /**
   * 已驳回
   */
  REFUSE = 'refuse'
}
